<template>
  <div>
    <BlockUI :blocked="loadingEdit" :fullScreen="true"></BlockUI>
    <ProgressSpinner v-if="loadingEdit" class="spinner" />
    <Card>
      <template #title>
        {{ $t("applications.applicationDetails") }}
      </template>

      <template #content>
        <div class="grid flex-column grid-nogutter">
          <div class="col">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="certificationType"
                  >{{ $t("applications.certification") }} *:</label
                >
              </div>
              <div class="col-12 md:col-4">
                <div class="field p-fluid">
                  <Dropdown
                    id="certificationType"
                    v-model="selectedCertificationType"
                    :options="certificationTypes"
                    optionLabel="name"
                    optionValue="id"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedCertificationType == 1" class="col mb-5 md:mb-0">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="certificationId"
                  >{{ $t("applications.productToCertify") }} *:</label
                >
              </div>
              <div class="col-12 md:col-4">
                <div class="field p-fluid mb-1">
                  <Dropdown
                    id="certificationId"
                    :class="{
                      'p-invalid':
                        v$.details.certificationId.$invalid && submitted,
                    }"
                    v-model.number="v$.details.certificationId.$model"
                    :options="certCategoriesNotDeleted"
                    optionLabel="schemeTitle"
                    optionValue="id"
                    panelClass="overlay"
                  >
                    <template #option="slotProps">
                      <div class="criteria-item">
                        <div>{{ slotProps.option.schemeTitle }}</div>
                      </div>
                    </template>
                  </Dropdown>
                </div>
              </div>
              <ul
                v-if="v$.details.certificationId.$error && submitted"
                class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3 mt-0"
              >
                <li
                  v-for="(error, index) of v$.details.certificationId.$errors"
                  :key="index"
                >
                  {{
                    error.$message.replace(
                      "[value]",
                      $t("applications.productToCertify")
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div v-else-if="selectedCertificationType == 2" class="col">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="certifiedId"
                  >{{ $t("applications.certificationId") }} *:</label
                >
              </div>
              <div class="col-12 md:col-4">
                <div class="field p-fluid mb-1">
                  <Dropdown
                    id="certifiedId"
                    :class="{
                      'p-invalid': v$.details.certifiedId.$invalid && submitted,
                    }"
                    v-model.number="v$.details.certifiedId.$model"
                    :options="certifications"
                    optionValue="id"
                  >
                  <template #option="slotProps">
                        <div>CERT-{{ slotProps.option.certificationCode }}</div>
                    </template>

                    <template #value="slotProps">
                    <div v-if="slotProps.value">
                        <div>CERT-{{ certifications.find(el => el.id == slotProps.value).certificationCode }}</div>
                    </div>
                    <div v-else>
                      {{$t('applications.chooseCertificationId')}}
                    </div>
                    </template>
                  </Dropdown>
                </div>
              </div>
              <ul
                v-if="v$.details.certifiedId.$error && submitted"
                class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3 mt-0"
              >
                <li
                  v-for="(error, index) of v$.details.certifiedId.$errors"
                  :key="index"
                >
                  {{
                    error.$message.replace(
                      "[value]",
                      $t("applications.certificationId")
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 md:col-10 pb-2 flex justify-content-between">
            <label for="companyBranches"
              >{{ $t("applications.companyBranches") }}:</label
            >
            <Button
              :label="$t('applications.addBranch')"
              autofocus
              @click="addBranch"
              style="margin-left: 1%; margin-right: 1%"
            />
          </div>
          <div class="col-12">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 pb-3 md:pb-0 md:col-2"></div>
              <div class="col-12 md:col-10">
                <!-- <div class="field"> -->
                <!-- <InputText
                  id="companyBranches"
                  :class="{
                    'p-invalid':
                      v$.details.companyBranches.$invalid && submitted,
                  }"
                  v-model="v$.details.companyBranches.$model"
                  aria-describedby="companyBranches-error"
                />
                <small
                  v-if="
                    (v$.details.companyBranches.$invalid && submitted) ||
                    v$.details.companyBranches.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.details.companyBranches.required.$message.replace(
                      "[value]",
                      $t("applications.companyBranches")
                    )
                  }}</small
                > -->
                <div
                  v-for="(branch, index) in details.companyBranches"
                  :key="index"
                >
                  <CompanyBranches
                    :branchValue="branch"
                    :submittedValue="submitted"
                    :indexValue="index"
                    @deleteBranch="removeBranch"
                  ></CompanyBranches>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="col mb-2 md:mb-0">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="numberOfFullTimeStuff"
                  >{{ $t("applications.numberOfFullTimeStuff") }} *:</label
                >
              </div>
              <div class="col-11 md:col-4">
                <div class="field p-fluid mb-1">
                  <InputNumber
                    id="numberOfFullTimeStuff"
                    :class="{
                      'p-invalid':
                        v$.details.numberOfFullTimeStuff.$invalid && submitted,
                    }"
                    v-model="v$.details.numberOfFullTimeStuff.$model"
                    aria-describedby="numberOfFullTimeStuff-error"
                  />
                </div>
              </div>
              <ul
                v-if="v$.details.numberOfFullTimeStuff.$error && submitted"
                class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3 mt-0"
              >
                <li
                  v-for="(error, index) of v$.details.numberOfFullTimeStuff
                    .$errors"
                  :key="index"
                >
                  {{
                    error.$message.replace(
                      "[value]",
                      $t("applications.numberOfFullTimeStuff")
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col">
          <div
            class="grid vertical-container align-items-baseline grid-nogutter"
          >
            <div class="col-2">
              <label for="numberOfShifts"
                >{{
                  $t("applications.numberOfShifts")
                }}:</label
              >
            </div>
            <div class="col">
              <div class="field">
                <InputText
                  id="numberOfShifts"
                  :class="{
                    'p-invalid': v$.details.numberOfShifts.$invalid && submitted,
                  }"
                  v-model="v$.details.numberOfShifts.$model"
                  aria-describedby="numberOfShifts-error"
                />
                <small
                  v-if="
                    (v$.details.numberOfShifts.$invalid && submitted) ||
                    v$.details.numberOfShifts.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.details.numberOfShifts.required.$message.replace(
                      "[value]",
                      $t("applications.numberOfShifts")
                    )
                  }}</small
                >
              </div>
            </div>
          </div>
        </div> -->
          <!-- <div class="col">
          <div
            class="grid vertical-container align-items-baseline grid-nogutter"
          >
            <div class="col-2">
              <label for="numberOfPeopleInShifts"
                >{{
                  $t("applications.numberOfPeopleInShifts")
                }}:</label
              >
            </div>
            <div class="col">
              <div class="field">
                <InputText
                  id="numberOfPeopleInShifts"
                  :class="{
                    'p-invalid':
                      v$.details.numberOfPeopleInShifts.$invalid && submitted,
                  }"
                  v-model="v$.details.numberOfPeopleInShifts.$model"
                  aria-describedby="numberOfPeopleInShifts-error"
                />
                <small
                  v-if="
                    (v$.details.numberOfPeopleInShifts.$invalid && submitted) ||
                    v$.details.numberOfPeopleInShifts.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.details.numberOfPeopleInShifts.required.$message.replace(
                      "[value]",
                      $t("applications.numberOfPeopleInShifts")
                    )
                  }}</small
                >
              </div>
            </div>
          </div>
        </div> -->
          <!-- <div class="col">
          <div
            class="grid vertical-container align-items-baseline grid-nogutter"
          >
            <div class="col-2">
              <label for="numberOfStaffBySubconstractors"
                >{{
                  $t(
                    "applications.numberOfStaffBySubconstractors"
                  )
                }}:</label
              >
            </div>
            <div class="col">
              <div class="field">
                <InputText
                  id="numberOfStaffBySubconstractors"
                  :class="{
                    'p-invalid':
                      v$.details.numberOfStaffBySubconstractors.$invalid &&
                      submitted,
                  }"
                  v-model="v$.details.numberOfStaffBySubconstractors.$model"
                  aria-describedby="numberOfStaffBySubconstractors-error"
                />
                <small
                  v-if="
                    (v$.details.numberOfStaffBySubconstractors.$invalid &&
                      submitted) ||
                    v$.details.numberOfStaffBySubconstractors.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.details.numberOfStaffBySubconstractors.required.$message.replace(
                      "[value]",
                      $t(
                        "applications.numberOfStaffBySubconstractors"
                      )
                    )
                  }}</small
                >
              </div>
            </div>
          </div>
        </div> -->
          <div class="col">
            <div
              class="grid vertical-container align-items-baseline grid-nogutter"
            >
              <div class="col-12 md:col-4 lg:col-2">
                <label for="numberOfPartTimeStaff"
                  >{{ $t("applications.numberOfPartTimeStaff") }}:</label
                >
              </div>
              <div class="col-11 md:col-4">
                <div class="field p-fluid">
                  <InputNumber
                    id="numberOfPartTimeStaff"
                    v-model.number="details.numberOfPartTimeStaff"
                    aria-describedby="numberOfPartTimeStaff-error"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="grid vertical-container align-items-baseline grid-nogutter"
            >
              <div class="col-12 md:col-4 lg:col-2 pl-5">
                <label for="durationOfEmploymentOfPartTimeStaff"
                  >{{ $t("applications.durationOfEmployment") }}:</label
                >
              </div>
              <div class="col-11 md:col-4 pl-5 md:pl-0">
                <div class="field p-fluid">
                  <InputNumber
                    id="durationOfEmploymentOfPartTimeStaff"
                    v-model.number="details.durationOfEmploymentOfPartTimeStaff"
                    aria-describedby="durationOfEmploymentOfPartTimeStaff-error"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="grid vertical-container align-items-baseline grid-nogutter"
            >
              <div class="col-12 md:col-4 lg:col-2">
                <label for="numberOfSeasonalStaff"
                  >{{ $t("applications.numberOfSeasonalStaff") }}:</label
                >
              </div>
              <div class="col-11 md:col-4">
                <div class="field p-fluid">
                  <InputNumber
                    id="numberOfSeasonalStaff"
                    v-model.number="details.numberOfSeasonalStaff"
                    aria-describedby="numberOfSeasonalStaff-error"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div
              class="grid vertical-container align-items-baseline grid-nogutter"
            >
              <div class="col-12 md:col-4 lg:col-2 pl-5">
                <label for="durationOfEmploymentOfSeasonalStaff"
                  >{{ $t("applications.durationOfEmployment") }}:</label
                >
              </div>
              <div class="col-11 md:col-4 pl-5 md:pl-0">
                <div class="field p-fluid">
                  <InputNumber
                    id="durationOfEmploymentOfSeasonalStaff"
                    v-model.number="details.durationOfEmploymentOfSeasonalStaff"
                    aria-describedby="durationOfEmploymentOfSeasonalStaff-error"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col">
          <div
            class="grid vertical-container align-items-baseline grid-nogutter"
          >
            <div class="col-2">
              <label for="numberOfStaffPerformingSimilarTasks"
                >{{
                  $t(
                    "applications.numberOfStaffPerformingSimilarTasks"
                  )
                }}:</label
              >
            </div>
            <div class="col">
              <div class="field">
                <InputText
                  id="numberOfStaffPerformingSimilarTasks"
                  :class="{
                    'p-invalid':
                      v$.details.numberOfStaffPerformingSimilarTasks
                        .$invalid && submitted,
                  }"
                  v-model="
                    v$.details.numberOfStaffPerformingSimilarTasks.$model
                  "
                  aria-describedby="numberOfStaffPerformingSimilarTasks-error"
                />
                <small
                  v-if="
                    (v$.details.numberOfStaffPerformingSimilarTasks.$invalid &&
                      submitted) ||
                    v$.details.numberOfStaffPerformingSimilarTasks.$pending
                      .$response
                  "
                  class="p-error"
                  >{{
                    v$.details.numberOfStaffPerformingSimilarTasks.required.$message.replace(
                      "[value]",
                      $t("applications.durationOfEmployment")
                    )
                  }}</small
                >
              </div>
            </div>
          </div>
        </div> -->
          <div class="col">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="companyRepresentative"
                  >{{ $t("applications.companyRepresentative") }} *:</label
                >
              </div>
              <div class="col-11 md:col-4 md:col-3">
                <div class="field p-fluid mb-1">
                  <InputText
                    id="companyRepresentative"
                    :class="{
                      'p-invalid':
                        v$.details.companyRepresentative.$invalid && submitted,
                    }"
                    v-model="details.companyRepresentative"
                    aria-describedby="companyRepresentative-error"
                  />
                </div>
              </div>
              <ul
                v-if="v$.details.companyRepresentative.$error && submitted"
                class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3 mt-0"
              >
                <li
                  v-for="(error, index) of v$.details.companyRepresentative
                    .$errors"
                  :key="index"
                >
                  {{
                    error.$message.replace(
                      "[value]",
                      $t("applications.companyRepresentative")
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="companyRepresentativeRole"
                  >{{ $t("applications.companyRepresentativeRole") }} *:</label
                >
              </div>
              <div class="col-11 md:col-4 md:col-3">
                <div class="field p-fluid mb-1">
                  <InputText
                    id="companyRepresentativeRole"
                    :class="{
                      'p-invalid':
                        v$.details.companyRepresentativeRole.$invalid &&
                        submitted,
                    }"
                    v-model="details.companyRepresentativeRole"
                    aria-describedby="companyRepresentativeRole-error"
                  />
                </div>
              </div>
              <ul
                v-if="v$.details.companyRepresentativeRole.$error && submitted"
                class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3 mt-0"
              >
                <li
                  v-for="(error, index) of v$.details.companyRepresentativeRole
                    .$errors"
                  :key="index"
                >
                  {{
                    error.$message.replace(
                      "[value]",
                      $t("applications.companyRepresentativeRole")
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col">
          <div
            class="grid vertical-container align-items-baseline grid-nogutter"
          >
            <div class="col-2">
              <label for="systemManager"
                >{{
                  $t("applications.systemManager")
                }}:</label
              >
            </div>
            <div class="col">
              <div class="field">
                <InputText
                  id="systemManager"
                  :class="{
                    'p-invalid':
                      v$.details.systemManager.$invalid && submitted,
                  }"
                  v-model="v$.details.systemManager.$model"
                  aria-describedby="systemManager-error"
                />
                <small
                  v-if="
                    (v$.details.systemManager.$invalid && submitted) ||
                    v$.details.systemManager.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.details.systemManager.required.$message.replace(
                      "[value]",
                      $t("applications.systemManager")
                    )
                  }}</small
                >
              </div>
            </div>
          </div>
        </div> -->
          <div class="col">
            <div class="grid align-items-baseline grid-nogutter">
              <div class="col-12 md:col-4 lg:col-2">
                <label for="desiredAuditDate"
                  >{{ $t("applications.desiredAuditDate") }} *:</label
                >
              </div>
              <div class="col-11 md:col-4 md:col-3">
                <div class="field p-fluid mb-1">
                  <Calendar
                    id="desiredAuditDate"
                    :minDate="new Date()"
                    :showIcon="true"
                    :class="{
                      'p-invalid':
                        v$.details.desiredAuditDate.$invalid && submitted,
                    }"
                    v-model="details.desiredAuditDate"
                    aria-describedby="desiredAuditDate-error"
                    dateFormat="dd/mm/yy"
                  />
                </div>
              </div>
              <ul
                v-if="v$.details.desiredAuditDate.$error && submitted"
                class="p-error text-xs col-10 col-offset-0 md:col-offset-2 pl-3 mt-0"
              >
                <li
                  v-for="(error, index) of v$.details.desiredAuditDate.$errors"
                  :key="index"
                >
                  {{
                    error.$message.replace(
                      "[value]",
                      $t("applications.desiredAuditDate")
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col">
            <span>* {{ $t("global.mandatoryFields") }}</span>
          </div>
        </div>
      </template>

      <template #footer>
        <div class="text-right">
          <Button
            :label="$t('global.cancel')"
            @click="cancel"
            class="p-button-danger"
          />
          <Button
            :label="$t('global.nextPage')"
            autofocus
            :loading="loadingButton"
            iconPos="right"
            @click="nextPage"
            style="margin-left: 1%; margin-right: 1%"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { required, requiredIf } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import CompanyBranches from "./CompanyBranches.vue";
import { mapGetters } from "vuex";

export default {
  name: "Details",
  components: {
    CompanyBranches,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loadingDropdown: false,
      loadingButton: false,
      selectedCertificationType: 1,
      certificationTypes: [
        {
          id: 1,
          name: this.$t("applications.new"),
        },
        {
          id: 2,
          name: this.$t("applications.renewal"),
        },
      ],
      details: {
        certificationId: null,
        certifiedId: null,
        certificationType: null,
        companyBranches: [],
        numberOfFullTimeStuff: null,
        numberOfPartTimeStaff: null,
        durationOfEmploymentOfPartTimeStaff: null,
        numberOfSeasonalStaff: null,
        durationOfEmploymentOfSeasonalStaff: null,
        companyRepresentative: "",
        companyRepresentativeRole: "",
        desiredAuditDate: null,
      },
      submitted: false,
      loadingEdit: false,
    };
  },
  validations() {
    return {
      details: {
        certificationId: {
          requiredIf: requiredIf(() => {
            return this.selectedCertificationType == 1;
          }),
        },
        certifiedId: {
          requiredIf: requiredIf(() => {
            return this.selectedCertificationType == 2;
          }),
        },
        numberOfFullTimeStuff: {
          required,
        },
        companyRepresentative: {
          required,
        },
        companyRepresentativeRole: {
          required,
        },
        desiredAuditDate: {
          required,
        },
      },
    };
  },
  watch: {
    async selectedCertificationType(val) {
      if (val == 2) {
        this.loadingCertfications = true;
        await this.$store.dispatch("certifications", {excludeRenewals: true});
        this.loadingCertfications = false;
      } else {
        if (this.$route.query.appId == "") {
          this.details.certifiedId = null;
        }
      }
    },
  },
  async beforeMount() {
    if ("appId" in this.$route.query && this.$route.query != "") {
      this.loadingEdit = true;
      await this.$store.dispatch("applicationById", this.$route.query.appId);
      this.loadingEdit = false;
      this.details = { ...this.getApplicationPdf };
      this.details.desiredAuditDate = this.$dayjs(
        this.details.desiredAuditDate,
        "DD/MM/YYYY"
      ).toDate();
    }
  },
  async mounted() {
    this.loadingDropdown = true;
    await this.$store.dispatch("schemes", "active");
    this.loadingDropdown = false;
    if(this.getApplicationPdf?.certificationType == "renew"){
      this.selectedCertificationType = 2
    }
  },
  computed: {
    ...mapGetters([
      "certCategoriesNotDeleted",
      "userInfo",
      "getApplicationPdf",
      "certifications",
    ]),
  },
  methods: {
    cancel() {
      this.$router.push({ name: "applications" });
    },
    async nextPage() {
      const result = await this.v$.$validate();
      this.submitted = true;
      console.log(result);
      if (result) {
        this.details.desiredAuditDate = (
          this.$dayjs(this.details.desiredAuditDate, "DD/MM/YYYY").isValid()
            ? this.$dayjs(this.details.desiredAuditDate, "DD/MM/YYYY")
            : this.$dayjs(this.details.desiredAuditDate)
        ).format("DD/MM/YYYY");
        this.details.userId = this.userInfo.sub;
        this.details.status = "draft";
        if (this.selectedCertificationType == 1) {
          this.details.certificationType = "new";
        } else {
          this.details.certificationId = this.certifications.find(el => el.id == this.details.certifiedId).categoryId
          this.details.certificationType = "renew";
        }
        // this.$store.commit("setApplicationPdf",this.details);
        this.loadingButton = true;
        try {
          if ("appId" in this.$route.query && this.$route.query != "") {
            await this.$store.dispatch("updateApplication", {
              value: this.details,
            });
          } else {
            await this.$store
              .dispatch("createApplication", this.details)
              .then(() => {
                if (this.selectedCertificationType == 1) {
                  this.$action(
                    "application-new",
                    {
                      applicationId: this.getApplicationPdf.id,
                    },
                    "application"
                  );
                } else {
                  this.$action(
                    "application-renewal",
                    {
                      applicationId: this.getApplicationPdf.id,
                      certificationId: this.details.certifiedId
                    },
                    "application"
                  );
                }
              });
            await this.$store.dispatch("clientInfo", {});
          }
          this.$emit("next-page", { pageIndex: 1, value: 50 });
          this.loadingButton = false;
        } catch (err) {
          this.loadingButton = false;
        }
      }
    },
    addBranch() {
      this.details.companyBranches.push({
        country: "",
        city: "",
        street: "",
        postalCode: "",
      });
    },
    removeBranch(val) {
      this.details.companyBranches.splice(val, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
// .field {
//   margin-bottom: 0;
// }

.col {
  min-height: 70px;
}

// .p-inputtext,
// .p-dropdown,
// .p-calendar {
//   width: 30%;
//   margin-right: 1%;
// }
</style>
