<template>
  <div class="grid grid-nogutter">
    <div class="col-10 md:col-8 grid flex-column grid-nogutter">
      <div class="col">
        <div class="grid align-items-baseline grid-nogutter">
          <div class="col-offset-1 col-11 md:col-2 md:col-offset-0">
            <label for="country">{{ $t("applications.country") }} *:</label>
          </div>
          <div class="col-12 md:col-5 col-offset-1 md:col-offset-0">
            <div class="field p-fluid mb-1">
              <InputText
                id="country"
                :class="{
                  'p-invalid': v$.branch.country.$invalid && submitted,
                }"
                v-model="v$.branch.country.$model"
                aria-describedby="country-error"
              />
            </div>
          </div>
          <ul
            v-if="v$.branch.country.$error && submitted"
            class="p-error text-xs col-8 col-offset-1 md:col-offset-2 pl-3 mt-0 mb-4"
          >
            <li
              v-for="(error, index) of v$.branch.country.$errors"
              :key="index"
            >
              {{
                error.$message.replace("[value]", $t("applications.country"))
              }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="grid align-items-baseline grid-nogutter">
          <div class="col-offset-1 col-11 md:col-2 md:col-offset-0">
            <label for="city">{{ $t("applications.city") }} *:</label>
          </div>
          <div class="col-12 md:col-5 col-offset-1 md:col-offset-0">
            <div class="field p-fluid mb-1">
              <InputText
                id="city"
                :class="{
                  'p-invalid': v$.branch.city.$invalid && submitted,
                }"
                v-model="v$.branch.city.$model"
                aria-describedby="city-error"
              />
            </div>
          </div>
          <ul
            v-if="v$.branch.city.$error && submitted"
            class="p-error text-xs col-8 col-offset-1 md:col-offset-2 pl-3 mt-0 mb-4"
          >
            <li v-for="(error, index) of v$.branch.city.$errors" :key="index">
              {{ error.$message.replace("[value]", $t("applications.city")) }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="grid align-items-baseline grid-nogutter">
          <div class="col-offset-1 col-11 md:col-2 md:col-offset-0">
            <label for="street">{{ $t("applications.street") }} *:</label>
          </div>
          <div class="col-12 md:col-5 col-offset-1 md:col-offset-0">
            <div class="field p-fluid mb-1">
              <InputText
                id="street"
                :class="{
                  'p-invalid': v$.branch.street.$invalid && submitted,
                }"
                v-model="v$.branch.street.$model"
                aria-describedby="street-error"
              />
            </div>
          </div>
          <ul
            v-if="v$.branch.street.$error && submitted"
            class="p-error text-xs col-8 col-offset-1 md:col-offset-2 pl-3 mt-0 mb-4"
          >
            <li v-for="(error, index) of v$.branch.street.$errors" :key="index">
              {{ error.$message.replace("[value]", $t("applications.street")) }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <div class="grid align-items-baseline grid-nogutter">
          <div class="col-offset-1 col-11 md:col-2 md:col-offset-0">
            <label for="postalCode"
              >{{ $t("applications.postalCode") }} *:</label
            >
          </div>
          <div class="col-12 md:col-5 col-offset-1 md:col-offset-0">
            <div class="field p-fluid mb-1">
              <InputText
                id="postalCode"
                :class="{
                  'p-invalid': v$.branch.postalCode.$invalid && submitted,
                }"
                v-model="v$.branch.postalCode.$model"
                aria-describedby="postalCode-error"
              />
            </div>
          </div>
          <ul
            v-if="v$.branch.postalCode.$error && submitted"
            class="p-error text-xs col-8 col-offset-1 md:col-offset-2 pl-3 mt-0 mb-4"
          >
            <li
              v-for="(error, index) of v$.branch.postalCode.$errors"
              :key="index"
            >
              {{
                error.$message.replace("[value]", $t("applications.postalCode"))
              }}
            </li>
          </ul>
        </div>
      </div>
      <Divider />
    </div>
    <div class="col">
      <Button
        icon="pi pi-times"
        class="p-button-rounded p-button-danger p-button-outlined"
        @click="deleteBranch(indexValue)"
      />
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "@/utilities/i18n-validators";

export default {
  name: "Company branches",
  props: ["branchValue", "submittedValue", "indexValue"],
  emits: ["deleteBranch"],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      branch: {
        country: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        postalCode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5),
          numeric,
        },
      },
    };
  },
  watch: {
    branch() {
      this.branchValue;
    },
  },
  computed: {
    branch() {
      return this.branchValue;
    },
    submitted() {
      return this.submittedValue;
    },
  },
  async mounted() {
    if (this.submitted) {
      await this.v$.$validate();
    }
  },
  methods: {
    deleteBranch(index) {
      this.$emit("deleteBranch", index);
    },
  },
};
</script>

<style lang="scss" scoped>
// .p-inputtext,
// .p-dropdown,
// .p-calendar {
//   width: 50%;
//   margin-right: 1%;
// }
</style>
